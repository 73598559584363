import React, { useEffect } from 'react';
import { PageProps, HeadFC } from 'gatsby';
import { Amplify } from 'aws-amplify';
import NavBar from '../components/NavBar';
// @ts-ignore
import Seo from '../components/seo';
// @ts-ignore
import config from '../aws-exports';
import SidebarWrapper from '../components/SidebarWrapper';
import PiwikPro from '@piwikpro/react-piwik-pro';
import { getLink } from '../util/linkUtils';

let isLocalhost;
let isFeat;
let devRedirect;
if (typeof window !== 'undefined') {
  isLocalhost = !!(window.location.hostname === 'localhost');
  isFeat = !!(window.location.hostname === 'feature.d2b2yr0jqmp0n5.amplifyapp.com');
  devRedirect = isLocalhost ? 'http://localhost:8000/' : undefined;
  devRedirect = isFeat ? 'https://feature.d2b2yr0jqmp0n5.amplifyapp.com/' : devRedirect;
}
// use correct URI in the right env
const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: devRedirect ? devRedirect : 'https://www.findgiftsfor.com/',
    redirectSignOut: devRedirect
      ? 'https://feature.d2b2yr0jqmp0n5.amplifyapp.com/'
      : config.oauth.redirectSignOut // include localhost?
  }
};

Amplify.configure(updatedAwsConfig);

type DataProps = {
  site: {
    buildTime: string;
  };
};
// [1,202,300]
const pageTitle = "Mother's Day Gift Ideas 2023";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const items: { name: string; description: string }[] = require('../searchResults/mothersday.json');

const IndexPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  useEffect(() => {
    PiwikPro.initialize(
      'aa4790e1-d8c1-4117-9d39-8bfc787a95f7',
      'https://falofofo.containers.piwik.pro'
    );
  }, []);

  return (
    <>
      <NavBar />
      <SidebarWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <p style={{ maxWidth: 800, width: '80%', marginTop: '2em' }}>
            <h1 style={{ fontFamily: 'Montserrat' }}>{pageTitle}</h1>
            {items.map((item) => {
              return (
                <p key={item.name}>
                  <a
                    href={getLink(item)}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}>
                    <h4 style={{ fontFamily: '"Press Start 2P"' }}>{item.name}</h4>
                  </a>
                  <h3 style={{ fontFamily: 'Montserrat', fontWeight: '200' }}>
                    {item.description}
                  </h3>
                </p>
              );
            })}
          </p>
        </div>
      </SidebarWrapper>
    </>
  );
};

export const Head: HeadFC<DataProps> = () => (
  <Seo title="Mother's Day Gift Ideas - FindGiftsFor ChatGPT Gift Search" />
);

export default IndexPage;
